import React from "react";
import axios from "./Api";

const UtilService = {
    subString(string, start, end) {
        return string.substring(start, end);
    },
    async callApi(object, cb) {
        let headers = {
            headers: {
                "Content-Type": "application/json",
                devicetype: 1,
            },
        };
        axios[object.method](object.url, object.request, headers)
            .then(({ data }) => {
                cb(null, data);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    cb(error.response);
                }
            });
    },
};

export default UtilService;
