import React, {Component} from 'react'
import {Link} from 'gatsby'
import frontSidebarSvg from './FrontSidebar'
import _ from 'lodash'

import $ from 'jquery'
import 'react-tippy/dist/tippy.css'


class FrontSidebarView extends Component {
    activeMenu(index) {
        $(`#links li`).each(function (ind) {
            $(`#icon_click${ind}`).removeClass('active-sidebar')
            if (index === ind) {
                $(`#icon_click${index}`).addClass('active-sidebar')
            }
        })
    }

    render() {
        const crnt_url = (typeof window !== 'undefined' && window || {})?.location?.pathname;
        let cuIndex = _.findIndex(frontSidebarSvg, { url: crnt_url });
        return (
            <div className="side-drawer hidden" id="sidebar_open">
                <div className="sidebar-scroll">
                    <ul id="links">
                        {frontSidebarSvg.map((dataSvg, index) => {
                            return (
                                <li
                                    key={index}
                                    id={`icon_click${index}`}
                                    className={
                                        index === cuIndex
                                            ? 'd-flex align-items-center active-sidebar'
                                            : 'd-flex align-items-center'
                                    }
                                    //className="d-flex align-items-center active-sidebar"
                                    onClick={this.activeMenu.bind(this, index)}
                                >
                                    <div>
                                        <Link to={dataSvg.url}>
                                            <div

                                                className="side-drawer-icon d-flex align-items-center"
                                            >
                                                {dataSvg.file}
                                            </div>
                                            <div className="sidebar-menu-name">{dataSvg.name}</div>
                                        </Link>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default FrontSidebarView
