import React, {Component} from 'react'
import {notification} from 'antd'
import _ from 'lodash'
import IntlMessages from '../../util/IntlMessages'
import UtilService from "../../services/util";
import OpenNotification from "../../components/common/CommonButton/OpenNotification";


class Suggestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {}
        }
        // this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleReset = () => {
        this.setState({
            fields: {}
        });
        document.getElementById('Create-Contact-form').reset()
    };

    handleSubmit = e => {
        e.preventDefault();
        let self = this;
        let reqObj = _.clone(this.state.fields);
        reqObj.isFrom=2;
        let obj = {
            method: 'post',
            url: '/front/contactus/create',
            request: reqObj
        };
        if (self.handleValidation()) {
            UtilService.callApi(obj, function(err, data) {
                if (err) {
                    return OpenNotification({
                        type: 'error',
                        title: err.data.message
                    })
                }
                if (data && data.code === 'OK') {
                    OpenNotification({
                        type: 'success',
                        title: data.message
                    });
                    self.handleReset();
                    self.props.handleCancel()
                }
            })
        }
    };

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields })
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let blankField = [];
        let invalidFiels = [];

        //Name
        if (!fields['name']) {
            blankField.push('Name');
            formIsValid = false;
            errors['name'] = 'Please enter full Name'
        }
        if (typeof fields['name'] !== 'undefined') {
            if (!fields['name'].match(/^[a-zA-Z ]+$/)) {
                invalidFiels.push('Name');
                formIsValid = false;
                errors['name'] = 'Please enter only letters.'
            }
        }
        //Email
        if (!fields['email']) {
            blankField.push('Email');
            formIsValid = false;
            errors['email'] = 'Please enter email address'
        }
        if (typeof fields['email'] !== 'undefined') {
            let lastAtPos = fields['email'].lastIndexOf('@');
            let lastDotPos = fields['email'].lastIndexOf('.');

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields['email'].indexOf('@@') === -1 &&
                    lastDotPos > 2 &&
                    fields['email'].length - lastDotPos > 2
                )
            ) {
                invalidFiels.push('Email');
                formIsValid = false;
                errors['email'] = 'Email must be of type xyz@gmail.com'
            }
        }
        if (!fields['mobile']) {
            blankField.push('Mobile');
            formIsValid = false;
            errors['mobile'] = 'Please enter mobile number'
        }

        if (typeof fields['mobile'] !== 'undefined') {
            if (!fields['mobile'].match(/(^[0-9]*)$/) || fields['mobile'].length > 10 || fields['mobile'].length < 10) {
                invalidFiels.push('Mobile');
                formIsValid = false;
                errors['mobile'] = 'Mobile number invalid'
            }
        }

        //messege
        if (!fields['messege']) {
            blankField.push('Message');
            formIsValid = false;
            errors['messege'] = 'Please enter message'
        }
        // if (typeof fields["messege"] !== "undefined") {
        //   if (!fields["messege"].match(/(^[0-9]*)$/)) {
        //     invalidFiels.push("Message")
        //     formIsValid = false;
        //     errors["messege"] = 'messege invalid';
        //   }
        // }

        if (blankField.length > 0) {
            let validateFields = blankField.map((f, index) => {
                return <span>{`${f}${index !== blankField.length - 1 ? ', ' : ''}`}</span>
            });
            notification.error({ message: 'Required Fields', description: validateFields })
        }
        if (invalidFiels.length > 0) {
            let validateFields = invalidFiels.map((f, index) => {
                return <span>{`${f} ${index !== invalidFiels.length - 1 ? ',' : ''}`}</span>
            });
            notification.error({ message: 'Invalid Fields', description: validateFields })
        }
        this.setState({ errors: errors });
        return formIsValid
    }

    render() {
        const fieldName = _.clone(this.state.fields);
        return (
            <div className="front_static">
                <div className="page-main page-current">
                    <div className="page-toload legacy-page" data-bodyclassname="legacy">
                        <main className="page-content" role="main">
                            <div className="contact-banner">
                                <div className="container d-flex flex-wrap-xs">
                                    <div className="contact-form-bn suggestion-form">
                                        {/*<h5> <IntlMessages id="app.GetinTouch" /></h5>*/}
                                        <form className="mt-30" id="Create-Contact-form"
                                              onSubmit={this.handleSubmit}
                                        >
                                            <div className="two-col-sf d-flex align-items-center j-space-between flex-wrap-sm flex-wrap-xs">
                                                <div
                                                    className="form-control full-width"
                                                    style={{
                                                        height: '80px'
                                                    }}>
                                                    <label htmlFor="name">
                                                        <IntlMessages id="app.name" />
                                                        &nbsp;
                                                        {!fieldName.name || fieldName.name === '' ? (
                                                            <span className="required">*</span>
                                                        ) : (
                                                            <span className="required" style={{ color: 'green' }}>
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        className={this.state.errors['name'] ? 'input-error' : ''}
                                                        onChange={this.handleChange.bind(this, 'name')}
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                    />
                                                    <span style={{ color: 'red', fontSize: '12px' }}>
                                                        {this.state.errors['name']}
                                                    </span>
                                                </div>
                                                <div
                                                    className="form-control full-width"
                                                    style={{
                                                        height: '80px'
                                                    }}>
                                                    <label htmlFor="email">
                                                        <IntlMessages id="app.EmailAddress" />
                                                        &nbsp;
                                                        {!fieldName.email || fieldName.email === '' ? (
                                                            <span className="required">*</span>
                                                        ) : (
                                                            <span className="required" style={{ color: 'green' }}>
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        className={this.state.errors['email'] ? 'input-error' : ''}
                                                        onChange={this.handleChange.bind(this, 'email')}
                                                        type="text"
                                                        id="email"
                                                        name="email"
                                                    />
                                                    <span style={{ color: 'red', fontSize: '12px' }}>
                                                        {this.state.errors['email']}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="two-col-sf d-flex align-items-center j-space-between flex-wrap-sm flex-wrap-xs">
                                                <div
                                                    className="form-control full-width"
                                                    style={{
                                                        height: '80px'
                                                    }}>
                                                    <label htmlFor="company">
                                                        <IntlMessages id="app.CompanyName" />
                                                    </label>
                                                    <input
                                                        onChange={this.handleChange.bind(this, 'company')}
                                                        type="text"
                                                        id="company"
                                                        name="company"
                                                    />
                                                </div>
                                                <div
                                                    className="form-control full-width"
                                                    style={{
                                                        height: '80px',
                                                        marginTop: '-4px'
                                                    }}>
                                                    <label htmlFor="mobile">
                                                        <IntlMessages id="app.my-account.mobile" />
                                                        &nbsp;
                                                        {!fieldName.mobile || fieldName.mobile === '' ? (
                                                            <span className="required">*</span>
                                                        ) : (
                                                            <span className="required" style={{ color: 'green' }}>
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        onChange={this.handleChange.bind(this, 'mobile')}
                                                        className={this.state.errors['mobile'] ? 'input-error' : ''}
                                                        type="number"
                                                        id="mobile"
                                                        name="mobile"
                                                    />
                                                    <span style={{ color: 'red', fontSize: '12px' }}>
                                                        {this.state.errors['mobile']}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-control full-width">
                                                <label htmlFor="messege">
                                                    <IntlMessages id="app.Message" />
                                                    &nbsp;
                                                    {!fieldName.messege || fieldName.messege === '' ? (
                                                        <span className="required">*</span>
                                                    ) : (
                                                        <span className="required" style={{ color: 'green' }}>
                                                            *
                                                        </span>
                                                    )}
                                                </label>
                                                <textarea
                                                    onChange={this.handleChange.bind(this, 'messege')}
                                                    className={this.state.errors['messege'] ? 'input-error' : ''}
                                                    id="messege"
                                                    cols="30"
                                                    rows="5"
                                                    name="messege"
                                                    // placeholder="Your message here"
                                                />
                                                <span style={{ color: 'red', fontSize: '12px' }}>
                                                    {this.state.errors['messege']}
                                                </span>
                                            </div>
                                            <div className="text-right text-center-xs">
                                                <button type="submit" className="button-tag btn btn-primary btn-small">
                                                    <IntlMessages id="app.SubmitMessage" />
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    }
}

export default Suggestion
