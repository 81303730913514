import React, {Component} from 'react'
import $ from 'jquery'
import {Avatar} from 'antd'
import {Link} from 'gatsby'
import IntlMessages from '../../../util/IntlMessages'
import {BASE_URL_ATTACH} from '../../../constants/Common'
import LazyImage from '../../common/lazyImage'
import LangDropdown from "../../Header/LangDropdown";
import UtilService from "../../../services/util";

/*$( document ).ready(function() {
        $('.hamb-menu').click(function () {
            $('.front-mobile-sidebar').addClass('active');
        })
    $('.hamburger--spin.is-active').click(function () {
        $('.front-mobile-sidebar').removeClass('active');
    })
});*/
class FrontHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: true,
            marqueData: {
                fullDescription: ""
            }
        };
    }
    setCookie = () => {
        this.setState({ showInfo: false })
    }
    componentDidMount() {
        $(document).ready(function () {
            $('.hamb-menu').click(function () {
                $('.chapter-selection').addClass('is--active')
            })
            $('.hamburger--spin').click(function () {
                $('.chapter-selection').removeClass('is--active')
            })

            $('.grid .grid__item').hover(function () {
                
                // Mouse over
                $(this).toggleClass('hovered')
            })
        })
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            
            if ($(document).scrollTop() > 50) {
                $("header").addClass("js-open");
            } else {
                $("header").removeClass("js-open");
            }
        });
        this.fetchdata()
    }

    fetchdata = () => {
        let obj = {
            method: "post",
            url: "/apis/banner/paginate",
            request: {
                filter: { type: ["6"], isActive: true }
            }
        };
        UtilService.callApi(obj, (err, data) => {
            if (data.data && data.data.popup && data.data.popup.id) {
                this.setState({
                    marqueData: data.data.popup
                })
            }
        });
    };


    render() {
        // let permissionData = this.props.commonData.pagePermission()
        // let FrontAccountDropdown = getData(permissionData)
        let FrontAccountDropdown = []
        // const tokenId = localStorage.getItem('hkw-token')
        // const user = JSON.parse(localStorage.getItem('hkw-user'))
        const tokenId = null
        const user = {};
        let Name = user && user.name ? user.name : user && user.username
        let avtarName = Name ? Name.charAt(0) : ''

        let headerClass = this.state.marqueData && !this.state.marqueData.isAfterLogin && this.state.marqueData.fullDescription && this.state.marqueData.fullDescription !== "" ? 'light-background scroll-up header-marquee' : 'light-background scroll-up';

        return (
            <>
                <header
                    id="header-main"
                    role="banner"
                    style={{ visibility: 'inherit', opacity: ' 1' }}
                    className={headerClass}
                >
                    {this.state.marqueData && !this.state.marqueData.isAfterLogin && this.state.marqueData.fullDescription && this.state.marqueData.fullDescription !== "" && <marquee className="Headermarquee"> {this.state.marqueData.fullDescription} </marquee>}
                    <Link to="/">
                        <h1 className="main-logo active">
                            <img
                                src={require('../../../../static/assets/img/static/m-logo.png')}
                                className="hidden-sm-up mobile-header-logo"
                                alt="Hari Krishna Exports Pvt. Ltd."
                            />
                            <img
                                src={require('../../../../static/assets/img/static/logo.png')}
                                className="hidden-xs"
                                alt="Hari Krishna Exports Pvt. Ltd."
                            />
                        </h1>
                    </Link>
                    <div className="chapter-selection">
                        <button className="chapter-selection__hamburger hamburger hamburger--spin is-active">
                            <span className="hamburger-label">
                                Chapter
                            <span>1</span>
                            </span>{' '}
                            <span className="hamburger-label hamburger-label--close">
                                <IntlMessages id="app.Close" />
                            </span>{' '}
                            <span className="hamburger-box">
                                <span className="hamburger-inner" />
                            </span>
                        </button>
                        <div className="chapter-selection__content ">
                            <img
                                src={require('../../../../static/assets/img/static/m-logo-w.png')}
                                className="hidden-sm-up the-human-link-logo"
                                alt="Hari Krishna Exports Pvt. Ltd."
                            />
                            <img
                                src={require('../../../../static/assets/img/static/logo-w.png')}
                                alt="Kiran"
                                className="the-human-link-logo hidden-xs"
                            />
                           {/* <div className="front-dropdown mobile-front-dropdown hidden-sm-up">
                                <li>
                                    <LangDropdown />
                                </li>
                            </div>*/}
                            <div className="hidden-sm-up">
                                    <li className="login-signup-btns mobile-login-btn">
                                        <a
                                            href={`${process.env.GATSBY_REDIRECT_URL}/signin`}
                                            className="btn-outline btn-primary-outline btn-small header-login">
                                            <IntlMessages id="app.signin.login" />
                                        </a>
                                        <a href={`${process.env.GATSBY_REDIRECT_URL}/signup`} className="btn btn-primary btn-small header-signup">
                                            <IntlMessages id="app.signin.register" />
                                        </a>
                                    </li>
                            </div>
                            <div className="grid grid--flex grid--flush ">
                                <Link to="/our-story" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img src={require('../../../../static/assets/img/static/hk-icon-w.png')} alt="Chapter I" />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.OurStory" />
                                            <span>1</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/4-brothers.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/4-brothers.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>
                                <Link to="/hk-achievements" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/achievements.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            {/*<IntlMessages id="app.Careers" />*/}
                                            <IntlMessages id="app.achievement" />

                                            <span>2</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/Achivement.jpg')}
                                        classname="chapter-selection__video"
                                        widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/Achivement.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>

                                <Link to="/entity-brand" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/about.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.EntityBrands" />
                                            <span>3</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/Brand-Indentity.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/Brand-Indentity.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>
                                <Link to="/services" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/manufacturing.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.Services" />

                                            <span>4</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/Servicess.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/Servicess.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>

                                <Link to="/corporate-social-responsibility" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/csr.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.CSR/Activities" />

                                            <span>5</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/csr.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/csr.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>
                                <Link to="/news-media" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/shopping.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.NewsMedia" />
                                            <span>6</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/News-and-Media.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/News-and-Media.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>
                                <Link to="/presence" className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/product.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.Presence" />
                                            <span>7</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/Our-presence.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/Our-presence.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </Link>
                                <a href={`/contact`} className="grid__item one-third md-one-whole">
                                    <div className="chapter-selection__grid-content">
                                        <img
                                            src={require('../../../../static/assets/img/static/menu-icon/contact.png')}
                                            alt="Chapter I"
                                        />
                                        <h4 className="chapter-selection__chapter-title">
                                            <IntlMessages id="app.customerCare.contact" />
                                            <span>8</span>
                                        </h4>
                                    </div>
                                    <LazyImage
                                        src={require('../../../../static/assets/img/static/menu-bg/contact.jpg')}
                                        classname="chapter-selection__video"
                                        // widthAuto={true}
                                        alt=""
                                    />
                                    {/* <img
                                    src={require('../../../assets/img/static/menu-bg/contact.jpg')}
                                    alt=""
                                    className="chapter-selection__video"
                                /> */}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="site-menus front-dropdown">
                        <ul id="menu">
                            <li className="hidden-xs">
                                <LangDropdown />
                            </li>
                            <li className="hidden-xs shapret" />&nbsp;
                        {!tokenId ? (
                                <li className="login-signup-btns hidden-xs">
                                    <a href={`${process.env.GATSBY_REDIRECT_URL}/signin`} className="btn-outline btn-primary-outline btn-small header-login">
                                        <IntlMessages id="app.signin.login" />
                                    </a>
                                    <a href={`${process.env.GATSBY_REDIRECT_URL}/signup`} className="btn btn-primary btn-small header-signup">
                                        <IntlMessages id="app.signin.register" />
                                    </a>
                                </li>
                            ) : (
                                    <li className="hidden-xs">
                                        <Link to="/" className="d-flex align-items-center user">
                                            {tokenId ? (
                                                <div>
                                                    {user && user.photoId ? (
                                                        <img
                                                            className="user-logo"
                                                            src={BASE_URL_ATTACH + user.photoId}
                                                            alt=""
                                                        />
                                                    ) : (
                                                            <Avatar>{avtarName && avtarName.toUpperCase()}</Avatar>
                                                        )}
                                                </div>
                                            ) : (
                                                    ''
                                                )}

                                            <div className="front-user-dopdown">
                                                {user && user.username ? (
                                                    <span style={{ marginLeft: 12, marginRight: 6 }}>{user.username}</span>
                                                ) : (
                                                        ''
                                                    )}
                                            </div>
                                        </Link>
                                        <ul className="menu-dropdown">
                                            <li className="menu-dropdown-name hidden-sm-up">
                                                {user && user.username ? (
                                                    <span style={{ marginLeft: 12, marginRight: 6 }}>{user.username}</span>
                                                ) : (
                                                        ''
                                                    )}
                                            </li>
                                            {FrontAccountDropdown.map((data, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={data.url || ''}>
                                                            <div key={index} className="d-flex align-items-center">
                                                                <div className="dataImage">{data.file}</div>
                                                                <span>{data.name}</span>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                            <li className="user-logout">
                                                <Link to="" onClick={this.userLogout} value="logout">
                                                    <span style={{ paddingLeft: 24, color: '#99f' }}><IntlMessages id='app.Logout' /></span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                )}
                            <li className="shapret hidden-xs" />
                            <li className="login-signup-btns">
                                <button className="hamb-menu">
                                    <span className="line" />
                                    <span className="line" />
                                    <span className="line" />
                                </button>
                            </li>
                        </ul>
                    </div>
                </header>
            </>
        )
    }
}

export default FrontHeader
