import React from "react";
import FrontHeader from "./Front-Header";
import FrontSidebar from "./FrontSidebarView";
import moment from "moment";

export default class FrontWrapper extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			seoAgain:false
		}
	}

	componentDidMount() {

		document.body.scrollTop = 0;
		// window.scrollTo(0, 0)
		
		// localStorage.setItem("hkw-expiry-date",1594969185)
		let expireDate = localStorage.getItem("hkw-expiry-date")
		let storevalue = moment(parseInt(expireDate))._d
		let currentDateValue = moment()._d
	
		if (expireDate) {
			if (currentDateValue > storevalue) {
				localStorage.removeItem("hkw-expiry-date")
				localStorage.removeItem("hkw-seo")
				this.setState({seoAgain:true})
			}
		}
	}
	render() {
		let currUrl = (typeof window !== 'undefined' && window || {})?.location?.pathname
		// const currentCheckURL=_.first(_.without((currUrl).split('/'),""));
		// const isApplyContentAreaCls = (_.indexOf(['presence'],currentCheckURL)) >= 0;
		const { children, location } = this.props
		return (
			<div className="front_static">
				<div className="thetop" />
				{!this.props.isForDevice && <FrontHeader />}
				<div className="flash-transition" />
				<div className="pageHeight without-footer  {isApplyContentAreaCls ? 'content-area' : ''}">
					{
						(currUrl !== "/" || currUrl !== '/hk-achievements') ?
							<FrontSidebar /> : null

					}
					{children}
				</div>
				{/*<SEO url={window.location.pathname} />*/}
				{/*{this.state.seoAgain && <SEO url={window.location.pathname} />}*/}
			</div>
		);
	}
}