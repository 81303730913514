import axios from 'axios';
import {BASE_URL} from '../constants/Common';

export default axios.create({
    baseURL: BASE_URL,
    // baseURL: "/",
    timeout: 1000 * 60 * 10,
    headers: {
        'Content-Type': 'application/json',
    }
});

